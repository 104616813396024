import React from 'react';
import { PrismicRichText } from '@prismicio/react'
import { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { linkResolver } from '../utils/link_resolver'

function Helper({name, data}) {
  //console.log('data: ', data);

  const [open, setOpen] = useState(false)
  const [helper, setHelper] = useState(false)

  const closeInfo = () => {
    setHelper(false)
  }

  const infoHandler = (id) => {
    setOpen(true)
    if (id === 'helper') {
      setHelper(true)
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
          <Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-500" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-500" leaveFrom="translate-x-0" leaveTo="translate-x-full" afterLeave={() => closeInfo()}>
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-6">
                        <div className="flex items-start justify-between">
                          {helper && 
                            <Dialog.Title className="font-poppins text-2xl font-semibold text-orange">{name}</Dialog.Title>
                          }
                          <div className="ml-3 flex h-7 items-center">
                            <button type="button" className="rounded-md bg-white text-grey-6 hover:text-black focus:outline-none focus:ring-2 focus:ring-orange focus:ring-offset-2" onClick={() => setOpen(false)}>
                              <span className="sr-only">Close panel</span>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="px-6 font-poppins">
                       
                        <div className="mt-4 prose prose-sm leading-tight">
                          <PrismicRichText linkResolver={linkResolver} field={data.prismicSizeGuideDogHarnesses.data.text.richText} />
                        </div>
                        <figure className="my-8">
                          <GatsbyImage className="w-full h-full object-cover block" image={data.prismicSizeGuideDogHarnesses.data.image.gatsbyImageData} alt={data.prismicSizeGuideDogHarnesses.data.image.alt ?? ''} />
                        </figure>
                        <div className="w-full font-poppins font-semibold">
                          <table className="w-full text-sm">
                            <tr className="bg-orange text-left border-b border-t border-diamond-50">
                              <th className="w-3/12 text-white p-3">Dog harness size</th>
                              <th className="w-9/12 text-white p-3">Suitable for the following dog breeds (examples and guidance only)</th>
                            </tr>
                            {data.prismicSizeGuideDogHarnesses.data.table_1.map((entry, i) => (
                              <React.Fragment key={i}>
                                {i%2 ? 
                                <tr className="border-b border-diamond-50">
                                  <td className="font-semibold p-3 text-diamond">{entry.heading1}</td>
                                  <td className="p-3 font-normal text-diamond-80">{entry.text1}</td>
                                </tr>
                                : 
                                <tr className="bg-diamond-10 border-b border-diamond-50">
                                  <td className="font-semibold p-3 text-diamond">{entry.heading1}</td>
                                  <td className="p-3 font-normal text-diamond-80">{entry.text1}</td>
                                </tr>
                                }
                              </React.Fragment>
                            ))}
                          </table>
                        </div>
                        <div className="w-full my-8 font-poppins font-semibold">
                          <table className="w-full text-sm">
                            <tr className="bg-orange text-left border-b border-t border-diamond-50">
                              <th className="w-5/12 text-white p-3">Dog harness size</th>
                              <th className="w-7/12 text-white p-3">Girth circumference (B)</th>
                            </tr>
                            {data.prismicSizeGuideDogHarnesses.data.table_2.map((entry, i) => (
                              <React.Fragment key={i}>
                                {i%2 ? 
                                <tr className="border-b border-diamond-50">
                                  <td className="font-semibold p-3 text-diamond">{entry.heading1}</td>
                                  <td className="p-3 font-normal text-diamond-80">{entry.text1}</td>
                                </tr>
                                : 
                                <tr className="bg-diamond-10 border-b border-diamond-50">
                                  <td className="font-semibold p-3 text-diamond">{entry.heading1}</td>
                                  <td className="p-3 font-normal text-diamond-80">{entry.text1}</td>
                                </tr>
                                }
                              </React.Fragment>
                            ))}
                          </table>
                        </div>

                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <button onClick={() => infoHandler('helper')} className="text-sm font-poppins font-bold text-diamond">
        Size Guide &#62;
      </button>
    </>
  );
}

export default Helper;